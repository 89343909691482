import styled from 'styled-components';

export const ContactContainer = styled.div`
    background: #010606;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media screen and (max-width: 900px) {
        height: 700px;
    }
`

export const ContactTitle = styled.h1`
    color: #F7F8FA;
    margin: 20px;
`

export const ContactPrompt = styled.p`
    color: #F7F8FA;
    margin-bottom: 20px;
`

export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;

    @media screen and (max-width: 800px) {
        width: 75vw;
    }
`

export const ContactInputLabel = styled.label`
    color: #F7F8FA;
    margin-bottom: 10px;
`

export const ContactInput = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 7px;
    border: none;
    outline: none;
`

export const ContactTextArea = styled.textarea`
    height: 30vh;
    padding: 10px;
    border-radius: 7px;
    border: none;
    outline: none;
`

export const SubmitAlert = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    background-color: ${({alertState}) => (alertState === 'success' ? '#2FD056' : '#E91640')};
    border-radius: 5px;
`

export const AlertMessage = styled.p`
    text-align: center;
    color: #F7F8FA;
    margin: 0 auto;
    margin-bottom: 20px;
`

export const SubmitButton = styled.button`
    display: flex;
    justify-content: center;
    border-radius: 50px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: #333399;
    color: #fff;
    width: 150px;
    padding: 15px 30px;
    margin: 20px auto;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #fff;
        color: #333399;
    }
`