import AboutImage from '../../images/undraw_about_me_wa29.svg';

export const about = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Me',
    headline: 'Developer',
    description: 'I am an educator turned developer that is always looking for opportunities to learn and grow.',
    buttonLabel: 'Connect with Me',
    buttonLink: 'contact',
    imgStart: false,
    img: AboutImage,
    alt: 'About',
    dark: true,
    primary: true,
    darkText: false
}
