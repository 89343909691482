import styled from 'styled-components';

export const ProjectsCard = styled.div`
    background: #F7F8FA;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 400px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
    }
`

export const ProjectsIcon = styled.img`
    height: 250px;
    width: 350px;
    margin-bottom: 10px;
`

export const ProjectsH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ProjectsP = styled.p`
    font-size: 1rem;
    text-align: center;
`
export const ProjectsLinkContainer = styled.div`
    display: flex;
`

export const ProjectsLink = styled.a`
    margin: 10px;
    text-decoration: none;
    font-weight: bold;
    color: #333399;

    &:hover {
        text-decoration: underline;
    }
`