import React, { useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Sidebar from '../components/Sidebar/Sidebar';
import HeroSection from '../components/HeroSection/HeroSection';
import InfoSection from '../components/InfoSection/InfoSection';
import { about } from '../components/InfoSection/Data';
import Projects from '../components/Projects/Projects';
import Footer from '../components/Footer/Footer';
import ContactSection from '../components/Contact/ContactSection';

function HomePage() {
    const [isOpen, setIsOpen ] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...about} />
            <Projects />
            <ContactSection />
            <Footer />
        </>
    )
}

export default HomePage
