import styled from 'styled-components';

export const ProjectsContainer = styled.div`
    height: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    height: 100vh;
    
    @media screen and (max-width: 1300px) {
        height: 1500px;
    }

    @media screen and (max-width: 900px) {
        height: 3000px;
    }
`

export const ProjectsH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`

export const ProjectsWrapper = styled.div`
    max-width: 1000px
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
    };

    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    };
`