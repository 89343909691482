import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import Image from '../../images/logo.png';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    NavImg,
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
} from './NavbarElements';

function Navbar({ toggle }) {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <NavImg 
                            src={Image} 
                            alt='Jason' 
                        />
                    </NavLogo>
                    <MobileIcon>
                        <FaBars onClick={toggle} />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks 
                                to="about" 
                                smooth="true" 
                                duration={500} 
                                spy={true}
                                exact='true' 
                                offset={-80}
                            >About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                                to="projects" 
                                smooth="true"
                                spy={true}
                                duration={500} 
                                exact='true' 
                                offset={-80}
                            >Projects</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks 
                                to="contact" 
                                smooth="true"
                                duration={500} 
                                spy={true}
                                exact='true' 
                                offset={-80}
                            >Contact Me</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar
