import React from 'react';
import ProjectCard from './ProjectCard';
import {
    ProjectsContainer,
    ProjectsH1,
    ProjectsWrapper
} from './ProjectsElements';
import { projectData } from './ProjectData';

function Projects() {
    return (
        <ProjectsContainer id="projects">
            <ProjectsH1>My Projects</ProjectsH1>
            <ProjectsWrapper>
                {projectData.map((project, id) => (
                    <ProjectCard 
                        key={id}
                        title={project.title}
                        image={project.image}
                        description={project.description}
                        demo={project.demo}
                        github={project.github}
                    />
                ))}
            </ProjectsWrapper>
        </ProjectsContainer>
    )
}

export default Projects
