import React from 'react';
import {
    ProjectsCard,
    ProjectsIcon,
    ProjectsH2,
    ProjectsP,
    ProjectsLinkContainer,
    ProjectsLink
} from './ProjectCardElements';

function ProjectCard({ title, image, description, demo, github }) {
    return (
        <ProjectsCard>
            <ProjectsIcon src={image} alt={title}/>
            <ProjectsH2>{title}</ProjectsH2>
            <ProjectsP>
                {description}
            </ProjectsP>
            <ProjectsLinkContainer>
                <ProjectsLink href={demo} target="__blank">Demo</ProjectsLink>
                <ProjectsLink href={github} target="__blank">Github</ProjectsLink>
            </ProjectsLinkContainer>
        </ProjectsCard>
    )
}

export default ProjectCard
