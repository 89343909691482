import React from 'react';
import { 
    HeroContainer, 
    HeroBackground, 
    VideoBackground,
    HeroContent, 
    HeroH1,
    HeroP,
    SocialMediaContainer,
    SocialMediaIcon
} from './HeroElements';
import Video from '../../videos/video(3).mp4';
import { 
    AiOutlineLinkedin, 
    AiFillGithub, 
    AiOutlineFilePdf 
} from 'react-icons/ai'

function HeroSection() {
    return (
        <HeroContainer>
            <HeroBackground>
                <VideoBackground autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBackground>
            <HeroContent>
                <HeroH1>Hi, I'm Jason</HeroH1>
                <HeroP>
                    and welcome to my portfolio!
                </HeroP>
                <SocialMediaContainer>
                    <SocialMediaIcon 
                        href='https://www.linkedin.com/in/jason-chui-21a38b209/'
                        target='__blank'
                    >
                        <AiOutlineLinkedin size='32' />
                    </SocialMediaIcon>
                    <SocialMediaIcon 
                        href='https://github.com/jason1794c/'
                        target='__blank'
                    >
                        <AiFillGithub size='32' />
                    </SocialMediaIcon>
                    <SocialMediaIcon 
                        href='https://drive.google.com/file/d/1kTkbuIFanM3vRw7x7w6Oxo_kOSkykPHj/view?usp=sharing'
                        target='__blank'
                    >
                        <AiOutlineFilePdf size='32' />
                    </SocialMediaIcon>
                </SocialMediaContainer>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
