import dealsmate from '../../images/dealsmate.png';
import birdbook from '../../images/birdbook.png';
import youtubeClone from '../../images/youtube-clone.png';
import indeedClone from '../../images/indeed-clone.png';
import bookList from '../../images/booklist.png';
import lotto from '../../images/lotto.png';


export const projectData = [
    {
        title: 'Deals Mate',
        image: dealsmate,
        description: "Deals Mate is a web application that allows the user to track the prices of items on Amazon that they wish to buy.",
        demo: "https://dealsmate123.herokuapp.com/",
        github: "https://github.com/hatchways/team-blt"
    },
    {
        title: 'Birdbook',
        image: birdbook,
        description: "Birdbook is a website that is made to be a visual replica of the social media site, Facebook. The website has authentication functionality and the user can post, and like/unlike.",
        demo: "https://bird-book.netlify.app/",
        github: "https://github.com/jason1794c/birdbook"
    },
    {
        title: 'Youtube Clone',
        image: youtubeClone,
        description: "The Youtube Clone is a visual replica of Youtube, and has a searching functionality that scrapes Youtube.",
        demo: "https://youtube-clone-123.herokuapp.com/",
        github: "https://github.com/jason1794c/youtube-clone"
    },
    {
        title: 'Indeed Clone',
        image: indeedClone,
        description: "The Indeed Clone is a visual replica of Indeed, and allows the user to search for jobs based on keywords and/or location.",
        demo: "https://indeed-clone-123.netlify.app/",
        github: "https://github.com/jason1794c/indeed-clone"
    },
    {
        title: 'Book List',
        image: bookList,
        description: "This book list web app allows the user to create a list of books with information on the book's title, price, category, and description.",
        demo: "https://booklist123.netlify.app/",
        github: "https://github.com/jason1794c/booklist"
    },
    {
        title: 'Lottery Number Generator',
        image: lotto,
        description: "This is a random number generator that is designed to output lottery numbers for Lotto MAX, Lotto 649, and the Daily Grand",
        demo: "https://lotto-generator.netlify.app/",
        github: "https://github.com/jason1794c/Lottery"
    }
]
