import React, { useState } from 'react';
import {
    ContactContainer,
    ContactTitle,
    ContactForm,
    ContactInputLabel,
    ContactInput,
    ContactTextArea,
    AlertMessage,
    SubmitAlert,
    SubmitButton
} from './ContactElements';
import emailjs, { init } from 'emailjs-com';
import { IoClose } from 'react-icons/io5'

// EmailJS details
init("user_N5DeFeFi568dHlUKYIC6c");
const service_id = 'service_wpdy6pc';
const template_id = 'template_b1yo6od';

function ContactSection() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitAlert, setSubmitAlert] = useState(false);
    const [alertState, setAlertState] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        const templateParams = {
            to_name: 'Jason',
            reply_to: email,
            from_name: name,
            message: message
        };
        
        emailjs.send(service_id, template_id, templateParams)
            .then(response => {
                console.log('SUCCESS!', response.status, response.text);
                setSubmitAlert(true);
                setAlertState('success')
            }, (error) => {
                console.log('FAILED...', error);
                setSubmitAlert(true);
                setAlertState('failed')
            });

        setName('');
        setEmail('');
        setMessage('');
    }

    const closeMessage = e => {
        e.preventDefault();
        setSubmitAlert(false);
        setAlertState('')
    }

    return (
        <ContactContainer id="contact">
            <ContactTitle>Contact Me</ContactTitle>   
            <ContactForm onSubmit={handleSubmit}>
                <ContactInputLabel htmlFor='name'>Name: </ContactInputLabel>
                <ContactInput 
                    id='name' 
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                />
                <ContactInputLabel htmlFor='email'>Email: </ContactInputLabel>
                <ContactInput 
                    id='email' 
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                />
                <ContactInputLabel htmlFor='message'>Message: </ContactInputLabel>
                <ContactTextArea 
                    id='message' 
                    rows='4' 
                    col='50'
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    required
                />
                { submitAlert && 
                    <SubmitAlert alertState={alertState}>
                        <IoClose 
                            style={{color: '#fff', cursor: 'pointer'}} 
                            size='20'
                            onClick={closeMessage} 
                        />
                        <AlertMessage>
                            { alertState === 'success' ? 'Message sent!' : `Error: message not sent. Alternatively, you can email me at jason1794c@gmail.com.`}
                        </AlertMessage>
                    </SubmitAlert>
                }
                <SubmitButton type='submit'>Submit</SubmitButton>
            </ContactForm>
        </ContactContainer>
    )
}

export default ContactSection
