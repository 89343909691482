import React from 'react';
import {
    FooterContainer,
    FooterWrap,
    WebsiteRights
} from './FooterElements';

function Footer() {
    return (
        <FooterContainer>
            <FooterWrap>
                <WebsiteRights>Jason Chui © {new Date().getFullYear()}</WebsiteRights>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
